<template>
  <div class="shopuser">
    <div class="title">
      <p>
        <span>手机号:</span
        ><input
          v-model="form.mobile"
          placeholder="请输入手机查询"
          type="text"
        />
      </p>
      <p style="margin-top: 20px">
        <span>用户名:</span
        ><input
          v-model="form.username"
          placeholder="请输入用户名查询"
          type="text"
        />
      </p>
      <p style="margin-top: 20px">
        <el-radio v-model="form.status" :label="''">全部</el-radio>
        <el-radio v-model="form.status" :label="1">开启</el-radio>
        <el-radio v-model="form.status" :label="2">禁用</el-radio>
        <el-button size="mini" type="primary" @click="shaixuan">筛选</el-button>
      </p>
    </div>
    <el-table
      :data="list"
      stripe
      border
      :header-row-style="{ height: '40px' }"
      :row-style="{ height: '50px' }"
    >
      <el-table-column prop="id" label="用户ID" width="100px">
      </el-table-column>
      <el-table-column prop="username" label="用户名" width="200px">
      </el-table-column>
      <el-table-column prop="mobile" label="电话" width="200px">
      </el-table-column>
      <el-table-column label="用户类型" width="100px">
        <div slot-scope="scope">
          <span v-if="scope.row.user_type === 1">普通用户</span>
          <span v-else>会员用户</span>
        </div>
      </el-table-column>
      <el-table-column label="用户角色" width="120px">
        <div>
          <!-- <el-tag size="mini" v-if="scope.row.role_name === null">暂无</el-tag>
          <el-tag type="warning" size="mini" v-else>{{
            scope.row.role_name
          }}</el-tag>
          <span class="set shou" @click="setRole(scope.row)">调整</span> -->
          用户
        </div>
      </el-table-column>
      <el-table-column label="头像" width="55">
        <div class="imgbox" slot-scope="scope">
          <img :src="scope.row.avatar" alt="" />
        </div>
      </el-table-column>
      <el-table-column label="注册时间" width="180">
        <div slot-scope="scope">
          {{ scope.row.createtime }}
        </div>
      </el-table-column>
      <el-table-column label="消费金额">
        <div slot-scope="scope">
          <span style="color: red">{{ scope.row.total_price }}</span>
        </div>
      </el-table-column>
      <!-- <el-table-column label="状态" width="150">
        <div slot-scope="scope">
          <el-tag v-if="scope.row.status == 1" type="success" effect="dark"
            >可用</el-tag
          >
          <el-tag v-else type="info" effect="dark">禁用</el-tag>
          <span
            class="setstatus shou"
            @click="setStatus(scope.row.id, scope.row.status)"
            >修改</span
          >
        </div>
      </el-table-column> -->
      <el-table-column label="操作">
        <div class="caozuo" slot-scope="scope">
          <el-button size="mini" type="danger" round disabled @click="del(scope.row.id)">删除</el-button>

          <!-- <div class="btnDel shou" @click="del(scope.row.id)">删除</div> -->
        </div>
      </el-table-column>
    </el-table>
    <el-pagination
      style="margin-top: 20px"
      @current-change="crtChg"
      :current-page="form.page"
      layout="prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>

    <el-dialog
      :title="'修改 ' + admin.username + '  的角色'"
      :visible.sync="setroleShow"
      width="24%"
    >
      <div>
        <p style="margin-bottom: 30px">
          当前角色:
          <span style="color: #111">{{
            admin.role_name == null ? "暂无角色" : admin.role_name
          }}</span>
        </p>
        <el-select v-model="role_id" placeholder="请选择">
          <el-option
            v-for="item in role_list"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="setroleShow = false">取 消</el-button>
        <el-button type="primary" @click="setroleSubmit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  shopuserlist,
  deluser,
  setUserStatus,
  setAdminRole,
  rolelist,

} from "@/api/api.js";
// import { showLoading, hideLoading } from "@/utils/loading.js";
export default {
  props: ["id"],
  components: {},
  name: "",
  data() {
    return {
      form: {
        username: "",
        status: "",
        mobile: "",
        page: 1,
        limit: 10,
      },
      list: [],
      total: 1,
      admin: {},
      setroleShow: false,
      role_list: [],
      role_id: null,
      shopRole_id: null,
    };
  },
  methods: {
    async setroleSubmit() {
      const { data } = await setAdminRole({
        role_id: this.role_id,
        id: this.admin.id,
      });
      if (data.code != 200) return this.$message.error(data.data);
      this.$message.success(data.data);
      this.setroleShow = false;
      this.getlist();
    },

    setRole(item) {
      this.admin = item;
      this.setroleShow = true;
    },

    async setStatus(id, status) {
      this.$confirm("是否真的修改用户状态, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let sts = status == 1 ? 2 : 1;
          const { data } = await setUserStatus({ user_id: id, status: sts });
          if (data.code != 200) return this.$message.error(data.data);
          this.list.forEach((item) => {
            if (item.id == id) {
              let index = this.list.indexOf(item);
              this.list[index].status = sts;
            }
          });
          this.$message({
            type: "success",
            message: "修改成功!",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消修改",
          });
        });
    },

    async del(id) {
      // const {data} = await deluser({id})
      console.log(id);
    },

    crtChg(e) {
      this.form.page = e;
      this.getlist();
    },

    shaixuan() {
      this.form.page = 1;
      this.form.limit = 10;
      this.getlist();
    },

    async getlist() {
      this.form.store_id = parseInt(this.id);
      const { data } = await shopuserlist(this.form);
      this.total = data.data.total;
      this.list = data.data.data;
    },

    async getRolelist() {
      const { data } = await rolelist();
      this.role_list = data.data.data;
      this.role_list.unshift({ name: "普通用户", id: 0 });
      console.log(this.role_list);
    },
  },
  created() {
    this.getlist();
    this.getRolelist();
  },
};
</script>

<style scoped lang='less'>
.shopuser {
  .title {
    font-size: 13px;
    background-color: #eee;
    padding: 10px;
    padding-top: 20px;
    margin: 5px 0 20px 0;
    p {
      input {
        height: 30px;
        margin-left: 5px;
        font-size: 12px;
      }
    }
  }
  .imgbox {
    width: 30px;
    img {
      width: 100%;
    }
  }
}
.setstatus {
  margin-left: 10px;
  border-bottom: 1px solid #888;
  display: inline-block;
  &:hover {
    color: #1aad19;
    border-bottom: 1px solid #1aad19;
  }
}
/deep/.el-table-column {
  padding: 0;
}
/deep/.set {
  margin-left: 10px;
  color: #777;
  font-size: 12px;
  padding-bottom: 2px;
  border-bottom: 1px solid #777;
  &:hover {
    color: #111;
    border-bottom: 1px solid #111;
  }
}
/deep/ .el-dialog__title {
  font-size: 16px !important;
}
</style>