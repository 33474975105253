<template>
  <el-card class="box-card" v-cloak>
    <div slot="header" class="tit">
      <h3 class="inBlock">门店详情</h3>
      <back></back>
    </div>
    <div class="box">
      <div class="shopmessage">
        <div class="left">
          <p><span>ID:</span>{{ detail.id }}</p>
          <p><span>名称:</span>{{ detail.name }}</p>
          <p>
            <span>地址:</span
            >{{
              detail.province_name +
              detail.city_name +
              detail.district_name +
              detail.address
            }}
          </p>
          <p>
            <span>营业时间:</span
            >{{ `${detail.business_starttime} - ${detail.business_endtime}` }}
          </p>
          <p><span>联系方式:</span>{{ detail.phone }}</p>
          <p><span>创建时间:</span>{{ detail.updatetime | datefilter }}</p>
        </div>
        <div class="right">
          <p><span>图片:</span><img :src="imghref+imgurl" alt="" /></p>
          <p><span>描述信息:</span>{{ detail.district_name }}</p>
        </div>
      </div>
      <el-tabs v-model="settab" type="card">
        <el-tab-pane label="我的访客" name="1">
          <shop-user :id="this.$route.query.id"></shop-user>
        </el-tab-pane>
        <el-tab-pane label="我的会员" name="2">
          <user-vip :id="this.$route.query.id"></user-vip>
        </el-tab-pane>
        <el-tab-pane label="我的成员" name="3">
          <uesr-members :id="this.$route.query.id"></uesr-members>
        </el-tab-pane>
        <el-tab-pane label="我的业务员" name="4">
          <salesman :id="this.$route.query.id"></salesman>
        </el-tab-pane>
      </el-tabs>
    </div>
  </el-card>
</template>

<script>
import { getshopdetail } from "@/api/api.js";
import ShopUser from "./components/shopuser.vue";
import UserVip from "./components/uservip.vue";
import UesrMembers from "./components/uesr_Members.vue";
import Salesman from "./components/salesman.vue"
import {getSystemsettings} from '../../api/getSystemsetting.js'
// import ShopAdd from "./shopAdd.vue";
export default {
  components: { ShopUser, UserVip,UesrMembers,Salesman },
  name: "shopdetail",
  data() {
    return {
      detail: {},
      imgurl: "",
      settab: "1",
	    imghref:''
    };
  },
  methods: {
    async getDetail(id) {
      const { data } = await getshopdetail(id);
      if (data.code != 200) return this.$message.error(data.data);
      this.detail = data.data;
      console.log(this.detail);
      let index = this.detail.images.indexOf(",");
      console.log(index);
      if (index == -1) {
        this.imgurl = this.detail.images;
      } else {
        this.imgurl = this.detail.images.substring(0, index);
      }
    },
  },
  created() {
    if (this.$route.query.id) {
      this.getDetail({ store_id: this.$route.query.id });
    }
    getSystemsettings.then(res=>{
      this.imghref = res
    })
  },
};
</script>

<style scoped lang='less'>
.box {
  .shopmessage {
    margin-bottom: 20px;
    background-color: #009688;
    display: flex;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 4px 4px 3px #3c86c0;
    .left {
      margin-right: 50px;
      p {
        color: #fff;
        font-size: 12px;
        padding: 5px 10px;
        span {
          font-size: 14px;
          color: #ddd;
          margin-right: 8px;
        }
      }
    }
    .right {
      p {
        font-size: 13px;
        color: #ddd;
        span {
          font-size: 15px;
          margin-right: 10px;
        }
        &:nth-child(1) {
          margin-bottom: 20px;
          display: flex;
          img {
            border: 0.5px solid #ebeef5;
            padding: 5px;
            width: 100px;
            vertical-align: top;
          }
        }
      }
    }
  }
}
</style>