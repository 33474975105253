<template>
    <el-card class="box-card">
        <div class="shopuser">
            <div class="title">
                <p>
                    <span>手机号:</span
                    ><input
                    v-model="form.user_phone"
                    placeholder="请输入手机查询"
                    type="text"
                    />
                </p>
                <p style="margin-top: 20px">
                    <span>用户名:</span
                    ><input
                    v-model="form.user_name"
                    placeholder="请输入用户名查询"
                    type="text"
                    />
                </p>
                <p style="margin-top: 20px">
                    <el-radio v-model="form.status" :label="''">全部</el-radio>
                    <el-radio v-model="form.status" :label="1">开启</el-radio>
                    <el-radio v-model="form.status" :label="2">禁用</el-radio>
                    <el-button size="mini" type="primary" @click="shaixuan">筛选</el-button>
                </p>
            </div>
            <el-table
                :data="list"
                stripe
                border
                :header-row-style="{ height: '40px' }"
                :row-style="{ height: '50px' }"
                >
                <el-table-column prop="id" label="ID" width="100px">
                </el-table-column>
                <el-table-column prop="username" label="业务员名称" width="200px">
                </el-table-column>
                <el-table-column prop="mobile" label="业务员手机号" width="200px">
                </el-table-column>
                <el-table-column label="用户类型" width="100px">
                    <div slot-scope="scope">
                    <span v-if="scope.row.user_type === 1">普通用户</span>
                    <span v-else>会员用户</span>
                    </div>
                </el-table-column>
                <el-table-column label="头像" width="55">
                    <div class="imgbox" slot-scope="scope">
                    <img :src="scope.row.avatar" alt="" />
                    </div>
                </el-table-column>
                <el-table-column label="升级时间" width="180">
                    <div slot-scope="scope">
                    {{ scope.row.upsalesman_time }}
                    </div>
                </el-table-column>
                <el-table-column label="操作">
                    <div class="caozuo" slot-scope="scope">
                    <el-button size="mini" type="danger" round @click="toDetail(scope.row.id)">查看下级用户</el-button>
                    </div>
                </el-table-column>
            </el-table>
            <el-pagination
                style="margin-top: 20px"
                @current-change="crtChg"
                :current-page="form.page"
                layout="prev, pager, next, jumper"
                :total="total"
                >
            </el-pagination>
        </div>

        <!-- 查看业务员下级用户 -->
        <el-dialog title="推荐用户信息" :visible.sync="salemandialog" width="40%">
            <div class="shopVip">
                <el-table :data="userlist" height="200" border style="width: 100%">
                    <el-table-column prop="id" label="客户id" width="180">
                    </el-table-column>
                    <el-table-column prop="username" label="客户名称" width="180">
                    </el-table-column>
                    <el-table-column prop="mobile" label="客户手机号"></el-table-column>
                    <el-table-column label="头像" width="55">
                        <div class="imgbox" slot-scope="scope">
                        <img :src="scope.row.avatar" alt="" />
                        </div>
                    </el-table-column>
                    <el-table-column label="绑定时间" width="180">
                        <div slot-scope="scope">
                        {{ scope.row.salesman_time }}
                        </div>
                    </el-table-column>
                </el-table>
                <el-pagination
                    style="margin-top: 20px"
                    @current-change="UsercrtChg"
                    :current-page="userform.page"
                    layout="prev, pager, next, jumper"
                    :total="UserTotal"
                    >
                </el-pagination>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="salemandialog = false">取 消</el-button>
            </span>
        </el-dialog>
        
    </el-card>
</template>

<script>
import {
  storeSalesmanList,
  storeSalesmanUserList
} from "@/api/api.js";
// import { showLoading, hideLoading } from "@/utils/loading.js";
export default {
  props: ["id"],
  components: {},
  name: "",
  data() {
    return {
      form: {
        store_id: "",
        user_name: "",
        user_phone: "",
        status: "",
        page: 1,
        limit: 10,
      },
      userform:{
        limit: 10,
        page: 1,
        salesman_id:""
      },
      list: [],
      userlist:[],
      total: 1,
      UserTotal:10,
      salemandialog:false
    };
  },
  methods: {
    crtChg(e) {
      this.page = e;
      this.getlist();
    },

    UsercrtChg(e) {
      console.log(e);
      this.shopForm.page = e;
      this.getstorelist()
    },

    shaixuan() {
      this.form.page = 1;
      this.form.limit = 10;
      this.getlist();
    },

    toDetail(id) {
        console.log(id)
        this.userform.salesman_id = id
        this.salemandialog = true;
        this.getuserlist();
    },

    async getuserlist() {
      const { data } = await storeSalesmanUserList(this.userform);
      this.userlist = data.data.data;
      this.UserTotal = data.data.total
    },

    async getlist() {
      this.form.store_id = parseInt(this.id);
      const { data } = await storeSalesmanList(this.form);
      this.total = data.data.total;
      this.list = data.data.data;
      this.list.forEach((item) => {
        item.upsalesman_time = this.$timeTo.time3(item.upsalesman_time);
    });
    },
  },
  created() {
    this.getlist();
  },
};
</script>

<style scoped lang='less'>
.shopuser {
  .title {
    font-size: 13px;
    background-color: #eee;
    padding: 10px;
    padding-top: 20px;
    margin: 5px 0 20px 0;
    p {
      input {
        height: 30px;
        margin-left: 5px;
        font-size: 12px;
      }
    }
  }
  .imgbox {
    width: 30px;
    img {
      width: 100%;
    }
  }
}
.setstatus {
  margin-left: 10px;
  border-bottom: 1px solid #888;
  display: inline-block;
  &:hover {
    color: #1aad19;
    border-bottom: 1px solid #1aad19;
  }
}
/deep/.el-table-column {
  padding: 0;
}
/deep/.set {
  margin-left: 10px;
  color: #777;
  font-size: 12px;
  padding-bottom: 2px;
  border-bottom: 1px solid #777;
  &:hover {
    color: #111;
    border-bottom: 1px solid #111;
  }
}
/deep/ .el-dialog__title {
  font-size: 16px !important;
}
</style>